#rekstrarvorur-loading-indicator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0);
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    transition: background-color 300ms;
}

#rekstrarvorur-loading-indicator > * {
 opacity: 0;
 transition: opacity 300ms; 
}

#rekstrarvorur-loading-indicator.is-active > * {
  opacity: 1; 
  transition: opacity 300ms;
}

#rekstrarvorur-loading-indicator.is-active {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  pointer-events: auto;
  transition: background-color 300ms;
}
